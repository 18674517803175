<template>
  <Dialog :header="titulo" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '70vw'}" :maximizable="false" :modal="true">
    <div>
      <DataTable :value="infoFacturaDetallada.data" responsiveLayout="scroll" class="p-datatable-sm text-xs">
        <Column field="ItemCode" header="Artículo"></Column>
        <Column field="Dscription" header="Descripción"></Column>
        <Column field="totalLote" header="Cantidad Lote"></Column>
        <Column header="Precio/Und">
          <template #body="slotProps">
            <span>{{ $h.formatCurrency(slotProps.data.PrecioUnd, 2) }}</span>
          </template>
        </Column>
<!--        <Column header="Total">-->
<!--          <template #body="slotProps">-->
<!--            <span>{{ $h.formatCurrency(slotProps.data.LineTotal, 2) }}</span>-->
<!--          </template>-->
<!--        </Column>-->
        <Column field="Bodega" header="Bodega"></Column>
        <Column field="Lote" header="Lote"></Column>
        <Column field="FechaVencimiento" header="Fecha Vencimiento Lote"></Column>
      </DataTable>
    </div>
    <div class="grid lg:grid-cols-2 md:grid-cols-1 pt-4">
      <div>
        <span class="p-text-bold mr-3">Comentarios:</span>
        <span>{{infoFacturaDetallada.comentarios}}</span>
      </div>
      <div>
        <span class="p-text-bold mr-3">Total:</span>
        <span>{{$h.formatCurrency(infoFacturaDetallada.data[0].DocTotal,2)}}</span>
      </div>
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeMaximizable" class="p-button-text"/>
      <Button label="Generar Entrega" icon="pi pi-check" @click="sendJson" autofocus/>
    </template>
  </Dialog>

</template>

<script>
import { apiGenerarEntrada } from '../services/entradasServices.js'
import { ref } from 'vue'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
export default {
  name: 'modalDetalladoFacturaGenerar',
  emits: ['refreshSearch'],
  setup (props, { emit }) {
    // variables
    const displayMaximizable = ref(false)
    const infoFacturaDetallada = ref([])
    const titulo = ref('')
    // methods
    const openModal = (dataToShow) => {
      displayMaximizable.value = true
      infoFacturaDetallada.value = dataToShow
      if (typeof infoFacturaDetallada.value.data !== 'undefined') {
        titulo.value = `Factura No. ${infoFacturaDetallada.value.data[0].DocNum} - ${infoFacturaDetallada.value.data[0].CardCode} ${infoFacturaDetallada.value.data[0].CardName}`
      }
    }
    const closeMaximizable = () => {
      displayMaximizable.value = false
    }
    const sendJson = async () => {
      if (typeof infoFacturaDetallada.value.data !== 'undefined') {
        const dataFactura = infoFacturaDetallada.value.data
        const comentarios = infoFacturaDetallada.value.comentarios
        const Document = {
          NumAtCard: `FEV${dataFactura[0].DocNum}`,
          DocDate: dayjs().format('YYYY-MM-DD'),
          DocDueDate: dayjs().format('YYYY-MM-DD'),
          TaxDate: dayjs().format('YYYY-MM-DD'),
          Comments: comentarios,
          CardCode: 'PM900249425', // PHARMASAN
          // CardCode: dataFactura[0].CardCode,
          U_PHR_UserWs: '',
          DocumentLines: []
        }
       const CodigoMedicamentos = [...new Set(dataFactura.map(item => item.ItemCode))]
        for (let i = 0; i < CodigoMedicamentos.length; i++) {
          const medicamento = dataFactura.filter(item => item.ItemCode === CodigoMedicamentos[i])
          const lotesId = [...new Set(medicamento.map(item => item.Lote))]
          const lotes = []
          for (let j = 0; j < lotesId.length; j++) {
            const loteInfo = medicamento.filter(item => item.Lote === lotesId[j])
            lotes.push({
              idLote: lotesId[j],
              FechaVencimiento: loteInfo[0].FechaVencimiento,
              Quantity: loteInfo.map(item => item.totalLote).reduce((a, b) => a + b)
            })
          }
          const DocumentLine = {
            ItemCode: medicamento[0].ItemCode,
            Quantity: medicamento.map(item => item.totalLote).reduce((a, b) => a + b),
            UnitPrice: medicamento[0].PrecioUnd,
            WarehouseCode: medicamento[0].Bodega,
            BatchNumbers: lotes.map((item) => {
              return {
                BatchNumber: item.idLote,
                ExpiryDate: item.FechaVencimiento,
                Quantity: item.Quantity,
                BaseLineNumber: i
              }
            }),
            // BatchNumbers: medicamento.map((item) => {
            //   return {
            //     BatchNumber: item.Lote,
            //     ExpiryDate: item.FechaVencimiento,
            //     Quantity: item.totalLote,
            //     BaseLineNumber: i
            //   }
            // }),
            DocumentLinesBinAllocations: lotes.map((curElement, index) => {
              return {
                BinAbsEntry: 1,
                Quantity: curElement.Quantity,
                AllowNegativeQuantity: 'tNO',
                SerialAndBatchNumbersBaseLine: index,
                BaseLineNumber: i
              }
            })
            // DocumentLinesBinAllocations: medicamento.map((curElement, index) => {
            //   return {
            //     BinAbsEntry: 1,
            //     Quantity: curElement.totalLote,
            //     AllowNegativeQuantity: 'tNO',
            //     SerialAndBatchNumbersBaseLine: index,
            //     BaseLineNumber: i
            //   }
            // })
          }
          Document.DocumentLines.push(DocumentLine)
        }
        const payload = {
          DocumentMarketing: JSON.stringify(Document)
        }
        await apiGenerarEntrada(payload).then((res) => {
         if (res.data.status !== 401) {
           Swal.fire({
             title: '¡Se ha generado la entrega!',
             text: `número de nuevo documento : ${ res.data.DocNum}`,
             icon: 'success',
             showConfirmButton: true
           }).then(() => {
             displayMaximizable.value = false
             emit('refreshSearch')
           })
         } else {
            Swal.fire({
              title: '¡Error!',
              text: res.data.message,
              icon: 'error',
              showConfirmButton: true
            })
         }
        }).catch((err) => {
          console.error(err)
        })
      }
    }
    return {
      displayMaximizable,
      infoFacturaDetallada,
      titulo,
      closeMaximizable,
      openModal,
      sendJson
    }
  }
}
</script>

<style scoped>

</style>
