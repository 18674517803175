import http from '@/libs/http'
const api = `${process.env.VUE_APP_MICROSERVICE_API_UT_PHARMEDICAS_ENTRADAS_MERCANCIA}`

const apiGenerarEntrada = (params) => http.post(`${api}/entradas/generar/entrada`, params, { headers: { loading: true } })
const apiListarEntradasGeneradas = (query) => http.get(`${api}/entradas/generadas`, { params: { ...query }, headers: { loading: true } })
const apiVerEntregaGenerada = (DocEntry) => http.get(`${api}/entradas/generadas/${DocEntry}`, { headers: { loading: true } })

export {
  apiGenerarEntrada,
  apiListarEntradasGeneradas,
  apiVerEntregaGenerada
}
