import http from '@/libs/http'
const api = `${process.env.VUE_APP_MICROSERVICE_API_UT_PHARMEDICAS_ENTRADAS_MERCANCIA}`

const apiBuscarFactura = (DocNum) => http.get(`${api}/facturas/${DocNum}`, { headers: { loading: true } })
const apiBuscarDetalladoFactura = (DocNum) => http.get(`${api}/facturas/detallado/${DocNum}`, { headers: { loading: true } })

export {
  apiBuscarFactura,
  apiBuscarDetalladoFactura
}
