<template>
  <div class="grid grid-cols-1 my-5">
    <div>
      <h2 class="text-lg font-medium truncate mr-5 capitalize">Generar entrada de mercancía</h2>
    </div>
  </div>
  <div class="grid grid-col-1 my-5">
    <div class="flex justify-end">
      <div class="px-5 w-full lg:w-1/3">
        <label for="factura">No. Factura</label>
        <InputText id="factura" type="text" v-model="DocNum" class="w-full" placeholder="ingrese No. factura"/>
      </div>
      <div class="flex align-bottom mt-5">
        <Button label="Buscar" icon="pi pi-search" iconPos="left" @click="buscarFactura" class="p-button-sm"/>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 py-5">
    <div class="box p-5">
      <div v-if="Lines.length > 0">
        <div class="grid-cols-1 pb-5">
          <h2 class="text-base font-bold">Información de la factura - {{ Lines[0].CardCode }}
            {{ Lines[0].CardName }}</h2>
        </div>
        <div class="grid-cols-1">
          <DataTable :value="Lines" responsiveLayout="scroll" class="p-datatable-sm text-xs" :scrollable="true" scrollHeight="500px">
            <Column field="ItemCode" header="Artículo" style="max-width: 110px; min-width: 110px" ></Column>
            <Column field="EstadoMd" header="Estado" style="max-width: 110px; min-width: 110px"></Column>
            <Column field="Dscription" header="Descripción" style="max-width: 510px; min-width: 510px"></Column>
            <Column field="descHus" header="Descripción HUS" style="max-width: 510px; min-width: 510px"></Column>
            <Column field="Quantity" header="Cantidad" style="max-width: 100px; min-width:100px;" ></Column>
            <Column header="Precio/Und" style="max-width: 110px; min-width:100px;">
              <template #body="slotProps">
                <span>{{ $h.formatCurrency(slotProps.data.Price, 2) }}</span>
              </template>
            </Column>
            <Column header="Total" style="max-width: 130px; min-width:130px">
              <template #body="slotProps">
                <span>{{ $h.formatCurrency(slotProps.data.LineTotal, 2) }}</span>
              </template>
            </Column>
            <Column field="Controlado" header="Controlado" style="max-width: 95px;min-width:95px"></Column>
            <Column field="Regulado" header="Regulado" style="max-width: 95px; min-width: 95px;"></Column>
            <Column field="IVA" header="IVA" style="min-width: 50px; max-width: 50px;"></Column>
            <Column header="Bodega" style="max-width: 200px; min-width: 200px;">
              <template #body="slotProps">
                <span>{{ slotProps.data.WhsCode }} - {{ slotProps.data.WhsName }}</span>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 pt-7 gap-7">
          <div class="flex flex-col">
            <label class="py-2">Comentarios : </label>
            <Textarea v-model="comentarios" :autoResize="true" rows="3"/>
          </div>
          <div class="flex flex-col justify-center items-end">
            <div>
              <span class="font-bold">Subtotal :</span>
              <span>{{ $h.formatCurrency(calcularTotales.subtotal, 2) }}</span>
            </div>
            <div>
              <span class="font-bold">Valor de IVA :</span>
              <span> {{ $h.formatCurrency(calcularTotales.totalIva, 2) }}</span>
            </div>
            <div>
              <span class="font-bold">Total Factura :</span>
              <span> {{ $h.formatCurrency(calcularTotales.total, 2) }}</span>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 pt-4">
          <div class="flex justify-end items-end">
            <Button label="Guardar" icon="pi pi-check" iconPos="left" @click="handlePreviewEntrega" class="p-button-success p-button-sm mr-5"/>
            <Button label="Cancelar" icon="pi pi-times" iconPos="left" @click="cleanData" class="p-button-danger p-button-sm"/>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col justify-center items-center">
          <div class="w-full sm:w-1/3 opacity-70">
            <svg data-name="Layer 1" style="height: 200px; margin: auto;" viewBox="0 0 647.63626 632.17383"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
              <path
                d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/>
              <path
                d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
              <path
                d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/>
              <circle cx="190.15351" cy="24.95465" r="20" fill="#6c63ff"/>
              <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/>
              <path
                d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
              <path
                d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
              <path
                d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/>
              <circle cx="433.63626" cy="105.17383" r="20" fill="#6c63ff"/>
              <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
            </svg>
          </div>
          <span class="text-sm sm:text-base font-bold text-red-400 my-4 normal-case text-center">No hay información para mostrar, ingrese número de factura</span>
        </div>
      </div>
    </div>
  </div>
  <modalDetallado ref="modalDetalladoRef" @refreshSearch="cleanData"/>
</template>

<script>
import modalDetallado from '../../../components/modalDetalladoFacturaGenerar.vue'
import { apiBuscarFactura, apiBuscarDetalladoFactura } from '../../../services/facturasServices'
import { ref, computed } from 'vue'
import Swal from 'sweetalert2'

export default {
  name: 'entradasMercanciaCreate',
  components: {
    modalDetallado
  },
  setup () {
    // referencias
    const modalDetalladoRef = ref()
    // variables
    const DocNum = ref('')
    const Lines = ref([])
    const comentarios = ref('')
    // metodos
    const buscarFactura = async () => {
      if (DocNum.value !== '') {
        await apiBuscarFactura(DocNum.value).then(response => {
          if (response.data.success) {
            Lines.value = response.data.data
          } else {
            Swal.fire({
              title: 'No se puede agregar la factura',
              text: response.data.message,
              icon: 'warning',
              confirmButtonText: 'Ok'
            })
          }
        })
      }
    }
    const handlePreviewEntrega = async () => {
      const mdsInactivos = Lines.value.filter(line => line.EstadoMd === 'Inactivo')
      const mdsSinDescHus = Lines.value.filter(line => line.descHus === '')
      if (mdsInactivos.length > 0) {
        Swal.fire({
          title: 'No se puede generar la entrada',
          text: 'Existen artículos inactivos',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        return
      }
      if (mdsSinDescHus.length > 0) {
        Swal.fire({
          title: 'No se puede generar la entrada',
          text: 'Existen artículos sin descripción de HUS',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        return
      }
      await apiBuscarDetalladoFactura(DocNum.value).then(response => {
        if (response.data.success) {
          const dataShow = {
            data: response.data.data,
            comentarios: comentarios.value
          }
          modalDetalladoRef.value.openModal(dataShow)
        } else {
          Swal.fire({
            title: 'Ya existe',
            text: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Ok'
          })
        }
      })
    }
    const cleanData = () => {
      DocNum.value = ''
      Lines.value = []
      comentarios.value = ''
    }
    const calcularTotales = computed(() => {
      let total = 0
      let totalIva = 0
      let subtotal = 0
      for (const line of Lines.value) {
        totalIva += (line.LineTotal * line.IVA) / 100
        subtotal += line.LineTotal
      }
      total = subtotal - totalIva
      return {
        total,
        totalIva,
        subtotal
      }
    })
    return {
      DocNum,
      Lines,
      comentarios,
      buscarFactura,
      handlePreviewEntrega,
      cleanData,
      calcularTotales,
      modalDetalladoRef
    }
  }
}
</script>

<style scoped>

</style>
